<template>
  <transition name="modal">
    <form autocomplete="off" novalidate>
      <div id="stripe-form" class="items-center justify-center modal-mask">
        <div class="flex items-center justify-center h-full">
          <div
            class="
              flex flex-col
              items-center
              justify-start
              px-8
              py-4
              bg-white
              rounded-lg
              x-sm:w-full
              sm:w-full
              md:w-2/3
              lg:w-1/2
            "
          >
            <div class="flex items-center w-full mt-2">
              <h1 class="text-2xl font-extrabold text-left text-qgo-primary-bg">
                USE ANOTHER CARD
              </h1>
            </div>
            <div class="flex items-center w-full mt-2">
              <span class="text-left">Card Owner Name</span>
            </div>
            <div class="flex items-center w-full">
              <input
                type="text"
                class="
                  flex-1
                  block
                  w-full
                  mt-1
                  border-0 border-b border-qgo-primary-bg
                  focus:border-qgo-secondary-bg focus:ring-0
                "
                placeholder="Name"
                v-model="input.name"
                @blur="onBlur('name')"
                @input="nameVerification"
              />
            </div>
            <div class="flex items-center w-full">
              <p
                class="text-xs italic text-left text-red-500 errorMsg mb-1 mt-1"
                v-if="!validStatus.name"
              >
                {{ errors.name }}
              </p>
            </div>

            <div class="flex items-center w-full mt-2">
              <span class="text-left">Card Owner Address</span>
            </div>
            <div class="flex items-center w-full">
              <input
                type="text"
                class="
                  flex-1
                  block
                  w-full
                  mt-1
                  border-0 border-b border-qgo-primary-bg
                  focus:border-qgo-secondary-bg focus:ring-0
                "
                placeholder="Country (2-character alphanumeric country code)"
                v-model="input.country"
                @blur="onBlur('country')"
                @input="countryVerification"
              />
            </div>
            <div class="flex items-center w-full">
              <p
                class="text-xs italic text-left text-red-500 errorMsg mb-1 mt-1"
                v-if="!validStatus.country"
              >
                {{ errors.country }}
              </p>
            </div>
            <div class="flex items-center w-full">
              <input
                type="text"
                class="
                  flex-1
                  block
                  w-full
                  mt-1
                  border-0 border-b border-qgo-primary-bg
                  focus:border-qgo-secondary-bg focus:ring-0
                "
                placeholder="State/Province"
                v-model="input.state"
                @blur="onBlur('state')"
                @input="stateVerification"
              />
            </div>
            <div class="flex items-center w-full">
              <p
                class="text-xs italic text-left text-red-500 errorMsg mb-1 mt-1"
                v-if="!validStatus.state"
              >
                {{ errors.state }}
              </p>
            </div>
            <div class="flex items-center w-full">
              <input
                type="text"
                class="
                  flex-1
                  block
                  w-full
                  mt-1
                  border-0 border-b border-qgo-primary-bg
                  focus:border-qgo-secondary-bg focus:ring-0
                "
                placeholder="City"
                v-model="input.city"
                @blur="onBlur('city')"
                @input="cityVerification"
              />
            </div>

            <div class="flex items-center w-full">
              <p
                class="text-xs italic text-left text-red-500 errorMsg mb-1 mt-1"
                v-if="!validStatus.city"
              >
                {{ errors.city }}
              </p>
            </div>
            <div class="flex items-center w-full">
              <input
                type="text"
                class="
                  flex-1
                  block
                  w-full
                  mt-1
                  border-0 border-b border-qgo-primary-bg
                  focus:border-qgo-secondary-bg focus:ring-0
                "
                placeholder="Postal/ZIP Code"
                v-model="input.zipcode"
                @blur="onBlur('zipcode')"
                @input="zipcodeVerification"
              />
            </div>
            <div class="flex items-center w-full">
              <p
                class="text-xs italic text-left text-red-500 errorMsg mb-1 mt-1"
                v-if="!validStatus.zipcode"
              >
                {{ errors.zipcode }}
              </p>
            </div>
            <div
              ref="card_element"
              id="card-element"
              class="w-full p-4 mx-1 my-2 bg-gray-200"
            />
            <div class="flex items-center justify-center w-full m-1">
              <button
                type="button"
                class="
                  w-4/5
                  p-2
                  mr-2
                  text-white
                  bg-qgo-primary-bg
                  hover:bg-green-700
                  focus:bg-green-700
                "
              >
                <div v-if="isLoading" class="flex items-center justify-center">
                  <span>Processing </span>
                  <div class="mx-2 loading-dots">
                    <div class="loading-dots--dot"></div>
                    <div class="loading-dots--dot"></div>
                    <div class="loading-dots--dot"></div>
                  </div>
                </div>
                <p @click="addNewCard()" v-if="!isLoading" id="button-text"
                  >Use Card</p
                >
              </button>
              <button
                class="
                  w-1/5
                  p-2
                  ml-2
                  text-white
                  bg-red-500
                  focus:bg-red-700
                  hover:bg-red-700
                "
                @click="exit"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </transition>
</template>

<script>
import { loadStripe } from "@stripe/stripe-js";

export default {
  props: {
    showAddFundPopupUp: Boolean,
  },
  emits: ["exit", "cardAdded"],
  data() {
    return {
      blurStatus: {
        name: "",
        country: "",
        state: "",
        city: "",
        zipcode: "",
      },
      input: {
        name: "",
        country: "",
        state: "",
        city: "",
        zipcode: "",
      },
      errors: {
        name: "",
        country: "",
        state: "",
        city: "",
        zipcode: "",
      },
      validStatus: {
        name: true,
        country: true,
        state: true,
        city: true,
        zipcode: true,
      },
      stripe: {},
      isLoading: false,
      popupmodal: false,
      response: "",
      card_name: "",
      card_country: "",
      card_state: "",
      card_city: "",
      card_zip: "",
      payment_method: "",
      style: {
        base: {
          color: "#000",
          fontWeight: 500,
          fontFamily: "Source Code Pro, Consolas, Menlo, monospace",
          fontSize: "16px",
          fontSmoothing: "antialiased",
        },
        invalid: {
          color: "#FC011F",
          "::placeholder": {
            color: "#FFCCA5",
          },
        },
      },
    };
  },
  methods: {
    nameVerification() {
      if (this.blurStatus.name) {
        if (this.input.name) {
          this.errors.name = "";
          this.validStatus.name = true;
        } else {
          this.errors.name = "Card owner name is required";
          this.validStatus.name = false;
        }
      } else {
        this.errors.name = "";
        this.validStatus.name = true;
      }
    },
    countryVerification() {
      if (this.blurStatus.country) {
        if (this.input.country) {
          if (this.input.country.length == 2) {
            this.errors.country = "";
            this.validStatus.country = true;
          } else {
            this.errors.country =
              "Address should be 2-character alphanumeric country code";
            this.validStatus.country = false;
          }
        } else {
          this.errors.country = "Address is required";
          this.validStatus.country = false;
        }
      } else {
        this.errors.country = "";
        this.validStatus.country = true;
      }
    },
    stateVerification() {
      if (this.blurStatus.state) {
        if (this.input.state) {
          this.errors.state = "";
          this.validStatus.state = true;
        } else {
          this.errors.state = "State is required";
          this.validStatus.state = false;
        }
      } else {
        this.errors.state = "";
        this.validStatus.state = true;
      }
    },
    cityVerification() {
      if (this.blurStatus.city) {
        if (this.input.city) {
          this.errors.city = "";
          this.validStatus.city = true;
        } else {
          this.errors.city = "City is required";
          this.validStatus.city = false;
        }
      } else {
        this.errors.city = "";
        this.validStatus.city = true;
      }
    },
    zipcodeVerification() {
      if (this.blurStatus.zipcode) {
        if (this.input.zipcode) {
          const regex = /^\d+$/;
          if (regex.test(this.input.zipcode)) {
            this.errors.zipcode = "";
            this.validStatus.zipcode = true;
          } else {
            this.errors.zipcode = "Zipcode should conatin numbers only";
            this.validStatus.zipcode = false;
          }
        } else {
          this.errors.zipcode = "Zipcode is required";
          this.validStatus.zipcode = false;
        }
      } else {
        this.errors.zipcode = "";
        this.validStatus.zipcode = true;
      }
    },
    initStripe() {
      this.$nextTick(async () => {
        this.stripe = await loadStripe(process.env.VUE_APP_PUBLISHABLE_KEY);
        let elements = this.stripe.elements();
        this.card = elements.create("card", {
          hidePostalCode: true,
          style: this.style,
        });
        this.card.mount(this.$refs.card_element);
      });
    },
    exit() {
      this.$emit("exit");
    },

    onBlur(field) {
      switch (field) {
        case "zipcode":
          this.blurStatus.zipcode = true;
          this.zipcodeVerification();
          break;
        case "city":
          this.blurStatus.city = true;
          this.cityVerification();
          break;
        case "state":
          this.blurStatus.state = true;
          this.stateVerification();
          break;
        case "name":
          this.blurStatus.name = true;
          this.nameVerification();
          break;
        case "country":
          this.blurStatus.country = true;
          this.countryVerification();
          break;

        default:
          break;
      }
    },

    addNewCard() {
      let that = this;
      let arr = ["zipcode", "city", "state", "country", "name"];
      for (let index = 0; index < arr.length; index++) {
        this.onBlur(arr[index]);
      }
      if (
        this.validStatus.name &&
        this.validStatus.country &&
        this.validStatus.state &&
        this.validStatus.city &&
        this.validStatus.zipcode
      ) {
        this.loading(true);
        let additionalData = {
          name: this.input.name,
          address_country: this.input.country,
          address_state: this.input.state,
          address_city: this.input.city,
          address_zip: this.input.zipcode,
        };
        this.stripe
          .createToken(this.card, additionalData)
          .then((result) => {
            if (result.error) {
              console.log(result.error);
              // Inform the user if there was an error.
              // var errorElement = document.getElementById("card-errors");
              // errorElement.textContent = result.error.message;

              that.loading(false);
              that.$swal.fire({
                icon: "error",
                title: result.error.message,
                timer: 3000,
                toast: true,
                position: "top-end",
                showConfirmButton: false,
              });
              console.log("createToken errrror");
            } else {
              that.createPaymentMethod(result.token);
            }
          })
          .catch((err) => {
            console.log("err in create token", err);
          });
      }
    },
    createPaymentMethod(token) {
      let that = this;
      let address = {
        city: this.input.city,
        country: this.input.country,
        postal_code: this.input.zipcode,
        state: this.input.state,
      };
      this.stripe
        .createPaymentMethod({
          type: token.type,
          card: this.card,
          billing_details: {
            name: this.input.name,
            address: address,
          },
        })
        .then(function (result) {
          // Handle result.error or result.paymentMethod
          if (result.error) {
            // Inform the user if there was an error.

            that.loading(false);
            that.$swal.fire({
              icon: "error",
              title: result.error.message,
              timer: 3000,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
            });
          } else {
            // Send the token to server.
            that.createVendorNewCard(token, result.paymentMethod);
          }
        })
        .catch((err) => {
          console.log("errrrrr", err);
        });
    },
    createVendorNewCard(token, paymentMethod) {
      let body = {
        token_id: token.id,
        paymentmethod_id: paymentMethod.id,
      };
      let user = JSON.parse(localStorage.getItem("userData"));
      this.$http
        .post("card/store", body)
        .then((response) => {
          this.response = response.data;
          let data = {
            val: "defaultPaymentMethod",
            e: token.card.last4,
          };
          this.$emit("cardAdded", { last4: token.card.last4 });
          this.$store.commit("smsTopUp/setInputData", data);
          this.$swal.fire({
            icon: "success",
            title: response.data.message,
            timer: 5000,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
          });
          // this.$swal.fire({
          //   toast: true,
          //   position: "top-end",
          //   showConfirmButton: false,
          //   timer: 4000,
          //   icon: "success",
          //   title: "Successfully Added New Card"
          // });
          this.loading(false);
          this.$emit("exit");
          if (this.showAddFundPopupUp) {
            this.$store.commit("smsTopUp/setShowAddFund", true);
          }
        })
        .catch((error) => {
          this.loading(false);
          console.error("There was an error!", error);
          this.$swal.fire({
            icon: "error",
            text: error.response.data.message,
            timer: 5000,
          });
        });
    },
    loading(isLoading) {
      this.isLoading = isLoading;
    },
    showError(errorMsg) {
      this.errorMsg = errorMsg;
      setTimeout(function () {
        this.errorMsg = "";
      }, 5000);
    },
  },
  mounted() {
    this.initStripe();
  },
};
</script>
