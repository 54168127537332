<template>
  <LoadingBar v-if="isLoading"></LoadingBar>
  <loading :active="isLoadingA" :is-full-page="true" :loader="'bars'" />
  <div class="flex flex-col h-full">
    <page-header>
      <page-title title="SMS Account Balance">
        <span class="px-5 py-2 ml-2"> $ {{ walletbalance }} </span>
      </page-title>
    </page-header>

    <div class="vendor-container">
      <div class="vendor-content">
        <div
          class="
            inline-block
            min-w-full
            overflow-hidden
            align-middle
            border-b border-gray-200
            sm:rounded-lg
          "
        >
          <div class="flex flex-col px-3 py-3 mt-5 bg-white sm:rounded-lg">
            <div class="flex items-center">
              <div class="flex-col flex-1">
                <label class="flex-1 block w-full mt-1 ml-2 text-left text-md">
                  Manually Add Funds
                </label>
              </div>
              <div
                class="flex items-center border-b"
                :class="
                  validators.fundAmount
                    ? 'text-red-500 border-red-500'
                    : 'text-qgo-primary-bg border-qgo-primary-bg'
                "
              >
                <p
                  class="px-2 ml-2"
                  :class="
                    validators.fundAmount
                      ? 'text-red-500 border-red-500'
                      : 'text-qgo-primary-bg border-qgo-primary-bg'
                  "
                >
                  Add Fund Amount
                </p>
                <p class="text-right text-black">
                  $
                  <input
                    type="number"
                    min="1"
                    class="
                      mt-0
                      px-0.5
                      border-0
                      focus:ring-0 focus:border-transparent
                      w-14
                      text-black
                    "
                    placeholder="00"
                    v-model="addFundAmount"
                  />
                </p>
              </div>
              <div class="flex px-1 py-1 sm:rounded-lg">
                <button class="px-2" @click="addFundClick">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 50 50"
                  >
                    <g
                      id="Group_286"
                      data-name="Group 286"
                      transform="translate(-1481 -808)"
                    >
                      <g
                        id="Ellipse_19"
                        data-name="Ellipse 19"
                        transform="translate(1481 808)"
                        fill="none"
                        stroke="#009A74"
                        stroke-width="2"
                      >
                        <circle cx="25" cy="25" r="25" stroke="none" />
                        <circle cx="25" cy="25" r="24" fill="none" />
                      </g>
                      <g
                        id="Group_238"
                        data-name="Group 238"
                        transform="translate(413 100)"
                      >
                        <path
                          id="Path_216"
                          data-name="Path 216"
                          d="M4591.953,932.809h21.555"
                          transform="translate(-3510.181 -200.086)"
                          fill="none"
                          stroke="#009A74"
                          stroke-width="2"
                        />
                        <path
                          id="Path_217"
                          data-name="Path 217"
                          d="M4638.75,885.242l8.49,8.49-8.49,8.49"
                          transform="translate(-3543.586 -161)"
                          fill="none"
                          stroke="#009A74"
                          stroke-width="2"
                        />
                      </g>
                    </g>
                  </svg>
                </button>
              </div>
            </div>
          </div>
          <div class="flex flex-col px-3 py-3 mt-1 bg-white sm:rounded-lg">
            <div class="flex items-center">
              <div class="flex-col flex-1">
                <p class="flex-1 block w-full mt-1 ml-2 text-left text-md">
                  Automatically Top Up Account
                </p>
              </div>
              <div
                class="flex items-center border-b"
                :class="
                  validators.defaultAmount
                    ? 'text-red-500 border-red-500'
                    : 'text-qgo-primary-bg border-qgo-primary-bg'
                "
              >
                <p
                  class="px-2 ml-2"
                  :class="
                    validators.defaultAmount
                      ? 'text-red-500 border-red-500'
                      : 'text-qgo-primary-bg border-qgo-primary-bg'
                  "
                >
                  Default Amount
                </p>
                <p class="text-right text-black">
                  $
                  <input
                    type="number"
                    min="1"
                    :disabled="autoTopUp"
                    class="
                      mt-0
                      px-0.5
                      border-0
                      focus:ring-0 focus:border-transparent
                      w-14
                      text-black
                    "
                    placeholder="00"
                    v-model="autoTopupAmount"
                  />
                </p>
              </div>
              <div>
                <p v-if="autoTopUp" class="px-2 ml-2 text-qgo-primary-bg">
                  ENABLED
                </p>
                <p v-else class="px-2 ml-2 text-qgo-primary-bg">DISABLED</p>
              </div>
              <div
                class="
                  relative
                  inline-block
                  align-middle
                  transition
                  duration-200
                  ease-in
                  select-none
                  w-14
                "
              >
                <input
                  type="checkbox"
                  name="push"
                  id="push"
                  class="toggle-checkbox"
                  @input="onChangeOfAutoTopup($event.target.checked)"
                  v-model="autoTopUp"
                  :readonly="!autoTopupAmount"
                />
                <label
                  for="push"
                  class="
                    block
                    h-8
                    overflow-hidden
                    bg-gray-300
                    rounded-full
                    cursor-pointer
                    toggle-label
                  "
                ></label>
              </div>
            </div>
          </div>
          <div class="px-3 mt-1">
            <h3 class="flex-1 w-full ml-2 text-xs text-left text-gray-600">
              Automatically topup when SMS Account Balance is below $5.00
            </h3>
          </div>
          <div class="flex flex-col px-3 py-3 mt-5 bg-white sm:rounded-lg">
            <div class="flex items-center">
              <div class="flex-col flex-1">
                <label
                  class="
                    flex-1
                    block
                    w-full
                    mt-1
                    ml-2
                    text-left
                    cursor-pointer
                    text-md
                  "
                >
                  Use Default Payment Method
                </label>
              </div>
              <div class="flex">
                <label class="inline-flex mt-3 mr-4 align-center">
                  <input
                    type="checkbox"
                    class="
                      w-6
                      h-6
                      text-green-600
                      rounded-xl
                      md:rounded-xl
                      focus:ring-0
                      radio-checker
                    "
                    name="paymentMethod"
                    v-model="defaultPaymentMethod"
                    :checked="defaultPaymentMethod"
                    @input="onDefaultCardChange($event.target.checked)"
                  />
                </label>
              </div>
            </div>
            <!-- <div class="flex items-center">
              <div class="flex-col flex-1">
                <label class="flex-1 block w-full mt-1 ml-2 text-left text-md">
                  **** **** **** 4444
                </label>
              </div>
              <div class="flex">
                <label class="inline-flex mt-3 mr-4 align-center">
                  <input
                    type="radio"
                    class="w-6 h-6 text-green-600 rounded-xl md:rounded-xl focus:ring-0 radio-checker"
                    name="paymentMethod"
                    value="4444"
                    :checked="inputData.defaultPaymentMethod === '4444'"
                    @change="setSmsInputData('defaultPaymentMethod', '4444')"
                  />
                </label>
              </div>
            </div>
            <div class="flex items-center">
              <div class="flex-col flex-1">
                <label class="flex-1 block w-full mt-1 ml-2 text-left text-md">
                  **** **** **** 5556
                </label>
              </div>
              <div class="flex">
                <label class="inline-flex mt-3 mr-4 align-center">
                  <input
                    type="radio"
                    class="w-6 h-6 text-green-600 rounded-xl md:rounded-xl focus:ring-0 radio-checker"
                    name="paymentMethod"
                    value="5556"
                    :checked="inputData.defaultPaymentMethod === '5556'"
                    @change="setSmsInputData('defaultPaymentMethod', '5556')"
                  />
                </label>
              </div>
            </div> -->
          </div>
          <div class="flex flex-col px-3 py-3 mt-1 bg-white sm:rounded-lg">
            <div class="flex items-center">
              <div class="flex-col flex-1">
                <label
                  class="
                    flex-1
                    block
                    w-full
                    mt-1
                    ml-2
                    text-left
                    cursor-pointer
                    text-md
                  "
                  @click="openNewCardModal"
                >
                  Use Different Card...
                </label>
              </div>
              <div class="flex">
                <button class="px-2" @click="openNewCardModal">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="30"
                    height="20"
                    viewBox="0 0 50 50"
                  >
                    <g
                      id="Group_286"
                      data-name="Group 286"
                      transform="translate(-1481 -808)"
                    >
                      <g
                        id="Ellipse_19"
                        data-name="Ellipse 19"
                        transform="translate(1481 808)"
                        fill="none"
                        stroke="#009A74"
                        stroke-width="2"
                      >
                        <circle cx="25" cy="25" r="25" stroke="none" />
                        <circle cx="25" cy="25" r="24" fill="none" />
                      </g>
                      <g
                        id="Group_238"
                        data-name="Group 238"
                        transform="translate(413 100)"
                      >
                        <path
                          id="Path_216"
                          data-name="Path 216"
                          d="M4591.953,932.809h21.555"
                          transform="translate(-3510.181 -200.086)"
                          fill="none"
                          stroke="#009A74"
                          stroke-width="2"
                        />
                        <path
                          id="Path_217"
                          data-name="Path 217"
                          d="M4638.75,885.242l8.49,8.49-8.49,8.49"
                          transform="translate(-3543.586 -161)"
                          fill="none"
                          stroke="#009A74"
                          stroke-width="2"
                        />
                      </g>
                    </g>
                  </svg>
                </button>
              </div>
            </div>
          </div>
          <div v-if="showAddFund">
            <AddFunds
              @exit="closeAddFunds"
              :fundAmount="parseFloat(inputData.fundAmount)"
              :defaultPaymentMethod="inputData.defaultPaymentMethod"
              :addFundAmount="addFundAmount"
              :last4="last4"
              @fundAdded="addFundAmount=0"
            />
          </div>
          <div v-if="showNewCardModal">
            <AddNewCard
              @exit="closeNewCardModal"
              :fundAmount="parseFloat(inputData.fundAmount)"
              :defaultPaymentMethod="inputData.defaultPaymentMethod"
              :showAddFundPopupUp="showAddFundPopup"
              @cardAdded="newCardAdded"
            />
          </div>
        </div>
      </div>
    </div>

    <page-footer>
      <footer-nav></footer-nav>
    </page-footer>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import PageHeader from "@/components/pages/Header.vue";
import PageTitle from "@/views/Vendor/PageTitle.vue";
import PageFooter from "@/components/pages/Footer.vue";
import FooterNav from "@/views/Vendor/FooterNav.vue";
import LoadingBar from "@/components/loader/Loader.vue";
import AddFunds from "@/views/Vendor/Modal/AddFunds.vue";
import AddNewCard from "@/views/Vendor/Modal/AddNewCard.vue";
import Loading from "vue-loading-overlay";

export default defineComponent({
  setup() {
    return {};
  },
  data() {
    return {
      isLoadingA: false,
      autoTopUp: false,
      autoTopupAmount: 0,
      walletbalance: 0,
      defaultPaymentMethod: false,
      last4: "",
      showAddFundPopup: false,
      addFundAmount: 0,
    };
  },
  components: {
    PageHeader,
    PageTitle,
    PageFooter,
    FooterNav,
    LoadingBar,
    AddFunds,
    AddNewCard,
    Loading,
  },
  methods: {
    onChangeOfAutoTopup(value) {
      if (value && !this.last4) {
        this.autoTopUp = 0;
        this.$swal.fire({
          icon: "error",
          title: "Please add atleast one card for payment",
          timer: 5000,
          toast: true,
          position: "top-end",
          showConfirmButton: false,
        });
        return;
      }
      if (value) {
        if (this.autoTopupAmount > 0) {
          this.updateAutoTopUp(value);
        } else {
          this.autoTopUp = 0;
        }
      } else {
        this.updateAutoTopUp(value);
      }
    },
    onDefaultCardChange(value) {
      if (value && !this.last4) {
        this.defaultPaymentMethod = 0;
        this.$swal.fire({
          icon: "error",
          title: "Please add atleast one card for payment",
          timer: 5000,
          toast: true,
          position: "top-end",
          showConfirmButton: false,
        });
      } else {
        this.defaultPaymentMethod = value
      }
    },
    newCardAdded(data) {
      this.last4 = data.last4;
      this.defaultPaymentMethod = true;
    },
    getCardinfo() {
      this.isLoadingA = true;
      this.$http
        .get("card/show")
        .then((response) => {
          console.log(response.data);
          this.isLoadingA = false;
          if (response.data.message == "No any default card exist") {
            this.defaultPaymentMethod = false;
            this.last4 = "";
          } else if (response.data.card && response.data.card.last4) {
            this.defaultPaymentMethod = true;
            this.last4 = response.data.card.last4;
          } else {
            this.defaultPaymentMethod = false;
            this.last4 = "";
          }
        })
        .catch((error) => {
          this.isLoadingA = false;
          this.$swal.fire({
            icon: "error",
            title: error.response.data.message,
            timer: 5000,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
          });
        });
    },
    updateAutoTopUp(value) {
      this.isLoadingA = true;
      let data = {
        auto_top_up: value,
        previous_payment: this.autoTopupAmount,
      };
      this.$http
        .post("card/updatesettings", data)
        .then((response) => {
          this.$swal.fire({
            icon: "success",
            title: response.data.message,
            timer: 5000,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
          });
          this.isLoadingA = false;
        })
        .catch((error) => {
          this.isLoadingA = false;
          this.$swal.fire({
            icon: "error",
            title: error.response.data.message,
            timer: 5000,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
          });
          console.log(error);
        });
    },
    getWalletBalance() {
      this.isLoadingA = true;
      this.$http
        .get("wallet/show")
        .then((response) => {
          this.walletbalance = response.data
            ? response.data.balance
              ? response.data.balance.balance
                ? response.data.balance.balance
                : 0
              : 0
            : 0;
          this.isLoadingA = false;
        })
        .catch((error) => {
          this.isLoadingA = false;
          console.log(error);
        });
    },

    getSettings() {
      this.isLoadingA = true;
      this.$http
        .get("card/settings")
        .then((response) => {
          this.autoTopUp =
            response.data.vendor.auto_top_up == true ? true : false;
          // this.autoTopUp = response.data
          //   ? response.data.vendor
          //     ? response.data.vendor.auto_top_up
          //       ? true
          //       : false
          //     : false
          //   : false;
          this.autoTopupAmount = response.data
            ? response.data.vendor
              ? response.data.vendor.previous_payment
                ? response.data.vendor.previous_payment
                : 0
              : 0
            : 0;
          this.isLoadingA = false;
        })
        .catch((error) => {
          this.isLoadingA = false;
          this.$swal.fire({
            icon: "error",
            title: error.response.data.message,
            timer: 5000,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
          });
        });
    },
    openAddFunds() {
      if (parseFloat(this.inputData.fundAmount) < 20) {
        this.$store.commit("smsTopUp/setShowAddFund", false);
        this.$store.commit("smsTopUp/setValidatorsFundAmount", true);
      } else {
        this.$store.commit("smsTopUp/setValidatorsFundAmount", false);
        if (this.inputData.defaultPaymentMethod) {
          this.$store.commit("smsTopUp/setShowAddFund", true);
        } else {
          this.$store.commit("smsTopUp/setShowNewCardModal", true);
        }
      }
    },
    addFundClick() {
      console.log(this.last4,this.defaultPaymentMethod);
      if (this.addFundAmount > 0) {
        if (this.last4 && this.defaultPaymentMethod) {
          this.$store.commit("smsTopUp/setShowAddFund", true);
        } else {
          this.showAddFundPopup = true;
          this.$store.commit("smsTopUp/setShowNewCardModal", true);
        }
      }
    },
    async subscribePusher(store) {
      let user = store.getters["auth/userData"];
      let event;
      if (user.id) {
        event = user.id;
      } else {
        let user = await JSON.parse(localStorage.getItem("userData"));
        event = user.id;
      }
      this.channel = this.$globalPusher().subscribe("user-" + event);
      this.channel.bind("stripe.update", function (data) {
        let payload = parseFloat(data.amount);
        store.commit("smsTopUp/setAccountBalance", payload);
      });
    },
    openNewCardModal() {
      this.showAddFundPopup = false;
      this.$store.commit("smsTopUp/setShowNewCardModal", true);
    },
    closeAddFunds() {
      this.$store.commit("smsTopUp/setShowAddFund", false);
      this.getWalletBalance();
    },
    closeNewCardModal() {
      this.$store.commit("smsTopUp/setShowNewCardModal", false);
    },
    setSmsInputData(val, e) {
      let name =
        val === "isEnabled"
          ? "auto_top-up"
          : val === "defaultPaymentMethod"
          ? "default-payment"
          : null;
      let data = {
        val: val,
        e: e,
      };
      console.log(data);
      this.$store.commit("smsTopUp/setInputData", data);
      if (name) {
        let payload = {
          key: "topup",
          name: name,
          value: e,
        };
        this.$store.dispatch("smsTopUp/updateSmsTopupData", payload);
        if (name === "auto_top-up") {
          let payload = {
            key: "topup",
            name: "previous_payment",
            value: this.inputData.defaultAmount,
          };
          this.$store.dispatch("smsTopUp/updateSmsTopupData", payload);
        }
      }
    },
    setAutoTopupAmount(val, e) {
      let data = {
        val: val,
        e: e,
      };
      console.log(data);
      this.$store.commit("smsTopUp/setInputData", data);
    },
  },
  watch: {},
  computed: {
    inputData() {
      return this.$store.getters["smsTopUp/inputData"];
    },
    accountBalance() {
      return this.$store.getters["smsTopUp/accountBalance"];
    },
    showAddFund() {
      return this.$store.getters["smsTopUp/showAddFund"];
    },
    showNewCardModal() {
      return this.$store.getters["smsTopUp/showNewCardModal"];
    },
    validators() {
      return this.$store.getters["smsTopUp/validators"];
    },
    isLoading() {
      return this.$store.getters["smsTopUp/isLoading"];
    },
  },
  created() {
    // this.$store.dispatch("smsTopUp/fetchSmsInitialData").then(
    //   response => {
    //     console.log(response);
    //   },
    //   error => {
    //     console.log(error);
    //     this.$swal.fire({
    //       icon: "error",
    //       text: "Something went wrong while fetching " + error,
    //       timer: 5000
    //     });
    //   }
    // );
  },
  mounted() {
    // this.subscribePusher(this.$store);
    this.getSettings();
    this.getWalletBalance();
    this.getCardinfo();
  },
  unmounted() {
    // let user = this.$store.getters["auth/userData"];
    // let event;
    // if (user.id) {
    //   event = user.id;
    // } else {
    //   let user = JSON.parse(localStorage.getItem("userData"));
    //   console.log(user);
    //   event = user.userId;
    // }
    // this.$globalPusher().unsubscribe("user-" + event);
  },
});
</script>
