<template>
  <loading :active="isLoadingA" :is-full-page="true" :loader="'bars'" />
  <transition name="modal">
    <form autocomplete="off">
      <div id="stripe-form" class="items-center justify-center modal-mask">
        <div class="flex items-center justify-center h-full">
          <!-- 
            
            <div
              ref="card_element"
              id="card-element"
              class="w-full p-4 mx-1 my-2 bg-gray-200"
              v-if="!defaultPaymentMethod"
            />
            <div v-if="defaultPaymentMethod">
              <p class="p-4 text-xl">
                Are you sure you want to load {{ fundAmount }} ?
              </p>
            </div>
            <p
              v-if="errorMsg"
              class="text-red-500"
              id="card-error"
              role="alert"
            >
              {{ errorMsg }}
            </p>
            <p v-if="paymentResult" class="result-message">
              {{ paymentResult }}
            </p>
            <div class="flex items-center justify-center w-full m-1">
              <button
                type="submit"
                class="w-4/5 p-2 mr-2 text-white bg-qgo-primary-bg hover:bg-green-700 focus:bg-green-700"
              >
                <div v-if="isLoading" class="flex items-center justify-center">
                  <span>Processing </span>
                  <div class="mx-2 loading-dots">
                    <div class="loading-dots--dot"></div>
                    <div class="loading-dots--dot"></div>
                    <div class="loading-dots--dot"></div>
                  </div>
                </div>
                <span v-if="!isLoading" id="button-text">Pay</span>
              </button>
              <button
                class="w-1/5 p-2 ml-2 text-white bg-red-500 focus:bg-red-700 hover:bg-red-700"
                @click="exit"
              >
                Cancel
              </button>
            </div>
          </div> -->
          <div class="modal-container">
            <div>
              <p class="p-4 text-xl">
                Are you sure you want to load {{ addFundAmount }} ?
              </p>
            </div>
            <p
              v-if="errorMsg"
              class="text-red-500"
              id="card-error"
              role="alert"
            >
              {{ errorMsg }}
            </p>
            <p v-if="paymentResult" class="result-message">
              {{ paymentResult }}
            </p>
            <div class="flex items-center justify-center w-full m-1">
              <button
                type="button"
                class="
                  w-4/5
                  p-2
                  mr-2
                  text-white
                  bg-qgo-primary-bg
                  hover:bg-green-700
                  focus:bg-green-700
                "
              >
                <div v-if="isLoading" class="flex items-center justify-center">
                  <span>Processing </span>
                  <div class="mx-2 loading-dots">
                    <div class="loading-dots--dot"></div>
                    <div class="loading-dots--dot"></div>
                    <div class="loading-dots--dot"></div>
                  </div>
                </div>
                <p
                  @click="addFundClick()"
                  v-if="!isLoading"
                  id="button-text"
                  class="w-full"
                >
                  Pay
                </p>
              </button>
              <button
                class="
                  w-1/5
                  p-2
                  ml-2
                  text-white
                  bg-red-500
                  focus:bg-red-700
                  hover:bg-red-700
                "
                @click="exit"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </transition>
</template>

<script>
import { defineComponent, ref } from "vue";
import { loadStripe } from "@stripe/stripe-js";
import store from "@/store";
import Loading from "vue-loading-overlay";
export default defineComponent({
  components: {
    Loading,
  },
  setup() {
    const fundAmount = ref(store.getters["smsTopUp/fundAmount"]);
    const defaultPaymentMethod = ref(
      store.getters["smsTopUp/defaultPaymentMethod"]
    );
    return {
      fundAmount,
      defaultPaymentMethod,
    };
  },
  props: {
    fundAmount: {
      type: Number,
      default: 0.0,
    },
    addFundAmount: {
      type: Number,
      default: 0.0,
    },
    last4: {
      type: String,
      default: "",
    },
  },
  emits: ['exit', 'fundAdded'],
  data() {
    return {
      isLoadingA: false,
      stripe: {},
      paymentResult: "",
      isLoading: false,
      errorMsg: "",
      card: null,
      clientSecret: "",
      style: {
        base: {
          color: "#000",
          fontWeight: 500,
          fontFamily: "Source Code Pro, Consolas, Menlo, monospace",
          fontSize: "20px",
          fontSmoothing: "antialiased",
          "::placeholder": {
            color: "#009A74",
          },
          ":-webkit-autofill": {
            color: "#e39f48",
          },
        },
        invalid: {
          color: "#FC011F",
          "::placeholder": {
            color: "#FFCCA5",
          },
        },
      },
    };
  },
  methods: {
    addFundClick() {
      console.log(this.last4);
      console.log(this.addFundAmount);
      if (this.last4 && this.addFundAmount > 0) {
        this.isLoadingA = true;
        let body = {
          last4: this.last4,
          amount: parseFloat(this.addFundAmount),
        };
        this.$http
          .post("payment/pay", body)
          .then((response) => {
            console.log(response.data);
            this.loading(false);
            if (response.data.message === "Action required") {
              this.$swal.fire({
                icon: "success",
                text: "Your card will send a verification, Please Verify!",
                timer: 5000,
              });
              window.open(response.data.data.next_action.redirect_to_url.url);
              setTimeout(() => {
                this.exit();
              }, 5000);
            } else if (response.data.message === "Payment succeeded") {
              this.$emit('fundAdded');
              this.$swal.fire({
                icon: "success",
                text: "Successfully Added Funds!",
                timer: 5000,
              });
              this.exit();
            } else if(response.data.status) {
              this.$emit('fundAdded');
              this.exit();
              this.$swal.fire({
                icon: "success",
                title: response.data.message,
                timer: 3000,
                toast: true,
                position: "top-end",
                showConfirmButton: false,
              });
            }

          })
          .catch((error) => {
            this.errorMessage = error.message;
            console.error("There was an error!", error);
            this.loading(false);
            this.$swal.fire({
              icon: "error",
              text: "Something went wrong while adding funds!",
              timer: 5000,
            });
            this.exit();
          });
      }
    },
    initStripe() {
      this.$nextTick(async () => {
        ////////////////////////////////////////
        // PUT *FETCH CLIENTSECRET* CODE HERE //
        ////////////////////////////////////////
        this.stripe = await loadStripe(process.env.VUE_APP_PUBLISHABLE_KEY);
        let elements = this.stripe.elements();
        this.card = elements.create("card", {
          hidePostalCode: true,
          style: this.style,
        });
        this.card.mount(this.$refs.card_element);
      });
    },
    exit() {
      this.$emit("exit");
    },
    addFund() {
      let that = this;
      this.loading(true);
      if (this.defaultPaymentMethod == "") {
        this.stripe
          .confirmCardPayment(this.clientSecret, {
            payment_method: {
              card: this.card,
            },
          })
          .then(function (result) {
            if (result.error) {
              // Show error to your customer
              that.showError(result.error.message);
              that.loading(false);
            } else {
              // The payment succeeded!
              that.showError("");
              that.orderComplete(result.paymentIntent.id);
            }
          });
      } else {
        /////////////////////////////////////////
        // SEND TO API AMOUNT + PAYMENT METHOD //
        /////////////////////////////////////////
        let finalAmount = parseFloat(this.fundAmount) * 100;
        let body = {
          last4: this.defaultPaymentMethod,
          amount: finalAmount,
        };
        let user = JSON.parse(localStorage.getItem("userData"));
        this.$http
          .post("stripe/topup/" + user.id, body)
          .then((response) => {
            console.log(response.data);
            this.loading(false);
            if (response.data.status === "requires_action") {
              this.$swal.fire({
                icon: "success",
                text: "Your card will send a verification, Please Verify!",
                timer: 5000,
              });
            } else if (response.data.status === "succeeded") {
              this.$swal.fire({
                icon: "success",
                text: "Successfully Added Funds!",
                timer: 5000,
              });
              this.$store.dispatch("smsTopUp/fetchAccountBalance");
            }

            this.exit();
          })
          .catch((error) => {
            this.errorMessage = error.message;
            console.error("There was an error!", error);
            this.loading(false);
            this.$swal.fire({
              icon: "error",
              text: "Something went wrong while adding funds!",
              timer: 5000,
            });
            this.exit();
          });
      }
    },
    orderComplete(paymentIntentId) {
      this.loading(false);
      this.paymentResult = "Payment Successful";
      console.log(paymentIntentId);
    },
    loading(isLoading) {
      this.isLoading = isLoading;
    },
    showError(errorMsg) {
      this.errorMsg = errorMsg;
      setTimeout(function () {
        this.errorMsg = "";
      }, 5000);
    },
  },
  mounted() {
    // if (this.defaultPaymentMethod == "") {
    //   this.initStripe();
    // }
  },
});
</script>
